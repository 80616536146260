module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4103644962039d9da8b5f84c63a3f06"},
    },{
      plugin: require('../node_modules/@raae/gatsby-theme-mui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-donations/gatsby-browser.js'),
      options: {"plugins":[],"option1":"unicorn"},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-let-it-snow/gatsby-browser.js'),
      options: {"plugins":[],"duration":10,"intensity":"blizzard","colors":["#FFB6C1"],"season":{"start":"2001-12-01T00:00:00.000Z","end":"2001-01-04T00:00:00.000Z"}},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-starter/gatsby-browser.js'),
      options: {"plugins":[],"option1":"unicorn"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
