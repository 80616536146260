exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baking-song-js": () => import("./../../../src/pages/bakingSong.js" /* webpackChunkName: "component---src-pages-baking-song-js" */),
  "component---src-pages-image-tags-js": () => import("./../../../src/pages/image-tags.js" /* webpackChunkName: "component---src-pages-image-tags-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-ola-tube-you-tube-id-js": () => import("./../../../src/pages/ola-tube/{youTube.id}.js" /* webpackChunkName: "component---src-pages-ola-tube-you-tube-id-js" */),
  "component---src-pages-olacast-js": () => import("./../../../src/pages/olacast.js" /* webpackChunkName: "component---src-pages-olacast-js" */),
  "component---src-pages-olacast-ola-tube-oem-bed-id-js": () => import("./../../../src/pages/olacast/{olaTubeOemBed.id}.js" /* webpackChunkName: "component---src-pages-olacast-ola-tube-oem-bed-id-js" */),
  "component---src-pages-olacast-you-tube-id-js": () => import("./../../../src/pages/olacast/[youTubeId].js" /* webpackChunkName: "component---src-pages-olacast-you-tube-id-js" */),
  "component---src-pages-pirates-js": () => import("./../../../src/pages/pirates.js" /* webpackChunkName: "component---src-pages-pirates-js" */),
  "component---src-pages-pizza-tags-js": () => import("./../../../src/pages/pizzaTags.js" /* webpackChunkName: "component---src-pages-pizza-tags-js" */),
  "component---src-pages-pizzas-js": () => import("./../../../src/pages/pizzas.js" /* webpackChunkName: "component---src-pages-pizzas-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

